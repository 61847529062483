<template>
  <div class="download" flex center align>
    <img src="" alt="" width="100%" height="100%" />
    <el-row :gutter="20">
      <!-- <el-col :span="4"><div class="blank_box"></div></el-col> -->
      <el-col :span="24">
        <div class="container content" flex between align>
          <div class="left">
            <p class="left_title">好安防，选邻云</p>
            <p class="left_tips">携手创信，共享视觉盛宴</p>
            <p class="left_tips">打造品牌，创造持续竞争力</p>
            <div class="qr_code" flex align>
              <div class="item" flex column center align>
                <img src="" alt="" width="100%" height="100%" />
                <p>邻云管家小程序</p>
              </div>
              <div class="item" flex column center align>
                <img src="" alt="" width="100%" height="100%" />
                <p>邻云管家公众号</p>
              </div>
            </div>
          </div>
          <div class="right" flex center align-start>
            <img src="" alt="" width="100%" height="100%" />
          </div>
        </div>
      </el-col>
      <!-- <el-col :span="4"><div class="blank_box"></div></el-col> -->
    </el-row>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {
    window.scrollTo(0,0)
  },
};
</script>
<style lang="less" scoped>
.download {
  position: relative;
  top: 80px;
  padding: 110px 0;
  min-width: 1200px;
  > img {
    width: 100%;
    height: 96%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .content {
    // height: 100%;
    height: 800px;
    width: 1200px;
    > div {
      height: 100%;
    }
    .left {
      width: 45%;
      padding-left: 35px;
      .left_title {
        font-size: 72px;
        font-family: ZhenyanGB;
        font-weight: 400;
        color: #f9c593;
        line-height: 64px;
      }
      .left_tips {
        font-size: 30px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #373840;
        line-height: 43px;
      }
      > p {
        padding: 15px 0;
        text-align: left;
      }
      .qr_code {
        padding-top: 50px;
        .item {
          padding-right: 30px;
        }
        img {
          width: 232px;
          height: 232px;
        }
        p {
          font-size: 23px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #373840;
          line-height: 36px;
          opacity: 0.9;
          padding: 20px 0;
        }
      }
    }
    .right {
      width: 40%;
      padding-top: 35px;
      img {
        width: 262px;
        height: 532px;
      }
    }
  }
}
@media screen and(max-width:1200px) {
  .download {
    top: 60px;
    padding: 70px 0;
    > img {
      height: 95.9%;
    }
    .content {
      height: 600px;
      width: 800px;
      .left {
        padding-left: 25px;
        .left_title {
          font-size: 45px;
          line-height: 45px;
        }
        .left_tips {
          font-size: 20px;
          line-height: 25px;
        }
        > p {
          padding: 8px 0;
        }
        .qr_code {
          padding-top: 30px;
          .item {
            padding-right: 20px;
          }
          img {
            width: 150px;
            height: 150px;
          }
          p {
            font-size: 18px;
            line-height: 25px;
            padding: 10px 0;
          }
        }
      }
      .right {
        padding-top: 24px;
        img {
          width: 180px;
          height: 366px;
        }
      }
    }
  }
}
@media screen and(min-width:1200.1px) and(max-width:1500px) {
  .download {
    top: 80px;
    padding: 90px 0;
    > img {
      height: 95.4%;
    }
    .content {
      height: 700px;
      width: 1000px;
      .left {
        padding-left: 35px;
        .left_title {
          font-size: 55px;
          line-height: 55px;
        }
        .left_tips {
          font-size: 26px;
          line-height: 35px;
        }
        > p {
          padding: 10px 0;
        }
        .qr_code {
          padding-top: 40px;
          .item {
            padding-right: 25px;
          }
          img {
            width: 200px;
            height: 200px;
          }
          p {
            font-size: 20px;
            line-height: 30px;
            padding: 15px 0;
          }
        }
      }
      .right {
        padding-top: 28px;
        img {
          width: 220px;
          height: 447px;
        }
      }
    }
  }
}
@media screen and(min-width:1500.1px) {
  .download {
    top: 80px;
    padding: 110px 0;
    .content {
      height: 800px;
      width: 1200px;
      .left {
        padding-left: 35px;
        .left_title {
          font-size: 72px;
          line-height: 64px;
        }
        .left_tips {
          font-size: 30px;
          line-height: 43px;
        }
        > p {
          padding: 15px 0;
        }
        .qr_code {
          padding-top: 50px;
          .item {
            padding-right: 30px;
          }
          img {
            width: 232px;
            height: 232px;
          }
          p {
            font-size: 23px;
            line-height: 36px;
            padding: 20px 0;
          }
        }
      }
      .right {
        padding-top: 35px;
        img {
          width: 262px;
          height: 532px;
        }
      }
    }
  }
}
</style>